<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CCol col="6" style="font-size: 25px">عرض الإعلان</CCol>
        </CCardHeader>
        <CCardBody>
          <div>
            <div class="row">
              <div class="col-md-6">
                <table class="table table-bordered">
                  <thead>
                    <tr class="titel-style">
                      <th>الاسم باللغة الانجليزية</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-model="subCategory.name"
                      :key="subCategory.id"
                      id="subCategory"
                    >
                      <td>{{ subCategory.name }}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr class="titel-style">
                      <th>الاسم باللغة العربية</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-model="subCategory.name_ar"
                      :key="subCategory.id"
                      id="subCategory"
                    >
                      <td>{{ subCategory.name_ar }}</td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr class="titel-style">
                      <th>الصورة</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <br />
                      <img
                        v-bind:src="subCategory.image_full_path"
                        style="height: 100px; width: 100px; margin-right: 15px"
                      />
                    </tr>
                    <br />
                  </tbody>
                </table>
              </div>
            </div>
            <CButton color="warning" @click="goBack">رجوع</CButton>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Multiselect from "vue-multiselect";
import swal from "sweetalert";
import $ from "jquery";
export default {
  name: "EditSubCategory",
  data() {
    return {
      subCategory: [],
      image: "",
      Categories: [],
      selected: "",
    };
  },
  created() {
    console.log(this.$route.params.id);
    this.$http
      .get(
        `${process.env.VUE_APP_URL}sub-categories/${this.$route.params.id}`
      )
      .then((response) => {
        this.subCategory = response.data.data;
        this.selected = response.data.data.category_id;
        console.log(this.selected);
      });
    let categories = [];
    this.$http
      .get(`${process.env.VUE_APP_URL}categories`)
      .then((response) => {
        $.each(response.data.data, function (key, value) {
          categories.push({ id: value.id, name: value.name });
        });
        this.Categories = categories;
      });
  },
  methods: {
    onFileChange(e) {
      this.subCategory.image = e.target.files[0];
      this.image = e.target.files[0];
      console.log(this.image);
    },
    UpdateSubCategory() {
      let formData = new FormData();
      if (this.image) formData.append("image", this.image);
      formData.append("name", this.subCategory.name);
      formData.append("name_ar", this.subCategory.name_ar);
      formData.append("category_id", this.selected);
      formData.append("_method", "PUT");

      this.$isLoading(true);
      this.$http
        .post(
          `${process.env.VUE_APP_URL}sub-categories/${this.$route.params.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.$isLoading(false);
          if (response.data.error) {
            swal({ title: response.data.message, buttons: "تم" });
          } else {
            swal({ title: response.data.message, buttons: "تم" });
            this.$router.push({ path: "sub-categories" });
          }
        });
    },
    goBack() {
      this.categoriesOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/sub-categories" });
    },
  },
};
</script>